// customization confirm example -> link_to icon, path, method: :delete, data: {confirm: 'are you sure?',
//      icon: 'icon_delete', warning: 'attention!!!', yes: 'Yes, confirm', no: 'Cancel', colorbtn: 'danger'
// }

Rails.confirm = function(message, link){

  let warning = $(link).data('warning') || ''
  if(warning.length !== 0){
    warning = `<div class="mt-2 rounded-2 p-2" style="background-color:#FEECDC; color:#B43403">${warning}</div>`
  }


  const modal = Swal.mixin({
    customClass: {
      confirmButton: `btn btn-outline-${$(link).data('colorbtn') || 'danger'}`,
      cancelButton: "btn"
    },
    buttonsStyling: false
  });
  modal.fire({
    title: $(link).data('icon') || '<i class="fa-thin fa-circle-xmark fa-3x text-danger"></i>',
    html: message + warning,
    showCancelButton: true,
    confirmButtonText: $(link).data('yes') || 'Elimina',
    cancelButtonText: $(link).data('no') || 'Annulla',
    reverseButtons: true,
    allowOutsideClick: false,
  }).then(result => {
    if (result.isConfirmed) {
      modal.fire({
        icon: "success",
        showConfirmButton: false,
        allowOutsideClick: false,
        html: '<h4>Operazione eseguita!</h4><h5 style="overflow: hidden">Aspettare che la pagina venga ricaricata</h5><div style="overflow: hidden"></div>',
      })
      setTimeout(()=>{
        let initial_state = Rails.confirm
        Rails.confirm = () => { return true }
        $(link)[0].click()
        if($(link).is('[data-remote="true"]')){ modal.close() }
        Rails.confirm = initial_state
      }, 750)
    }
  });
}
